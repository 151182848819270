<template>
  <div id="fdetail">
    <div class="fdetail">
      <Top />
    </div>
    <div class="fwtab">
      <div :class="{ active: checked == 0 }" @click="tabClick(0)">国际海运</div>
      <div :class="{ active: checked == 1 }" @click="tabClick(1)">国际空运</div>
      <div :class="{ active: checked == 2 }" @click="tabClick(2)">
        东南亚陆运
      </div>
      <div :class="{ active: checked == 3 }" @click="tabClick(3)">国际电商</div>
      <div :class="{ active: checked == 4 }" @click="tabClick(4)">仓储服务</div>
    </div>
    <!-- 国际海运 -->
    <div class="details" v-show="checked == 0">
      <div class="jianjie-title">
        <div class="jianjie-title-e">INTERNATIONAL SHIPPING</div>
        <div class="jianjie-title-c">国际海运</div>
      </div>
      <ul>
        <li>
          <p class="textsa">
            广东凯洋国际物流供应链有限公司秉承“我们不限于客户的满意，我们力求做到杰出”的服务理念，成立至今不断发展壮大，先后在广州、深圳、
            中山 、东莞 、上海、青岛、天津、
            厦门、福州、宁波、大连等港口城市设立了分支机构，在全国范围内建立起较为完善的运输服务代理网络，并逐步在世界各主要港口布建国际服务网络。
          </p>
        </li>
        <li>
          <p class="titles">海运出口运输—20大船公司一级订舱代理</p>
          <p class="textsa">
            主营海运集装箱货物、拼箱货物、海运散杂货运输等海运出口服务。凯洋物流是YML、WHL、COSCO、MAERSK、CHINA
            SHIPPING、MSC、CMA、HANJIN、OOCL、HYUNDAI、K-LINE等多家船东的一级订舱代理，在国内及全球拥有完善的代理网络，能为货主提供从订舱、清关、转关、仓储、代办货运保险、代办各种证书、代理检验/检疫等一条龙的国际运输服务。
          </p>
        </li>
        <li>
          <p class="titles">
            海运清关专家—马来西亚、越南、缅甸、美加、欧洲、日本、马来西亚双清DDP/DDU到门
          </p>
          <p class="textsa">
            我司具备专业从事马来西亚、越南、缅甸、美国、加拿大、欧洲、日本、澳洲、双清
            到门DDU/DDP，有年度BOND和PVA授权，可提供多国DDU/DDP风险低、价格优、时速快、服务好的双清关
            、派送、以及相关的一条龙服务。
          </p>
        </li>
        <li>
          <p class="titles">海运进口运输—进口清关，配送一条龙</p>
          <p class="textsa">
            承接FOB门到门、港至港的全程代理或办理进口到货前后的一切业务。协助客户缮制各种单证；订舱、报关、仓储、中转、集装箱拼装拆箱、结算运杂费、报关、报验、保险、以及相关的内陆运输服务和运输咨询业务。
          </p>
        </li>
      </ul>
    </div>
    <!-- 国际空运 -->
    <div class="details" v-show="checked == 1">
      <div class="jianjie-title">
        <div class="jianjie-title-e">INTERNATIONAL AIR TRANSPORT</div>
        <div class="jianjie-title-c">国际空运</div>
      </div>
      <ul>
        <li>
          <p class="textsa">
            广东凯洋国际物流供应链有限公司自成立以来，在海运业务飞速发展的同时，一直致力拓展国际航空货物运输，以便为客户提供更为全面的服务。我们以深圳、香港、广州机场为基地，在全球建立了完善的网络，在欧洲、美加、非洲、大洋洲、中东、东南亚等各大海空港城市都有稳定的代理网络，能在全球范围内为客户提供门到门服务，并跟多家航空公司、货代、海关等相关单位建立密切的合作关系。
          </p>
        </li>
        <li>
          <p class="titles">空运出口—空运门到门一条龙</p>
          <p class="textsa">
            与全球50多家国际知名航空公司，承接中国—全球空运出口、空运DDU\DDP及包机业务，公司与南方航空、韩亚航空、新加坡航空、北欧航空、奥地利航空、印度航空公司、阿拉伯水晶航空、新加坡航空、上海航空等多家航空公司签定订舱代理协议，同时依托全球80个国家的代理服务网络，为国内货主企业提供取货、包装、仓储、报关、空运订舱、送货、保险等一条龙服务。
          </p>
        </li>
        <li>
          <p class="titles">
            空运清关专家 —马来西亚、越南、缅甸、
            美加、欧洲、日本、澳洲空运双清到门
          </p>
          <p class="textsa">
            我司具备专业从事越南、缅甸、马来西亚、美国、加拿大、欧洲、日本、澳洲空运双清到门DDU/DDP，有年度BOND和PVA授权，可提供多国
            DDU/DDP风险低、价格优、时速快、服务好的双清关
            、派送、以及相关的一条龙服务。
          </p>
        </li>
        <li>
          <p class="titles">空运进口—进口清关，国内配送到门一条龙</p>
          <p class="textsa">
            在国外发货前，将由我司海外代理公司将运单、航班、件数、重量、品名、实际收货人及其他地址、联系电话等内容发给目的地代理公司。并协助客户审核客户提供的各类清单和批件；制作各类单证；订舱、报关的工作，并办理在国内的进口报关、纳税、转运、送货等代理业务。
          </p>
        </li>
      </ul>
    </div>
    <!-- 东南亚陆运 -->
    <div class="details" v-show="checked == 2">
      <div class="jianjie-title">
        <div class="jianjie-title-e">SOUTHEAST ASIA LAND TRANSPORT</div>
        <div class="jianjie-title-c">东南亚陆运</div>
      </div>
      <ul>
        <li>
          <p class="textsa">
            广东凯洋国际物流供应链有限公司是一家专业从事东南亚国际物流的物流公司、专业化国际运输、物流、仓储、快递、配送、进出口代理、报关、清关、海关监管、供应链管理、物流方案设计等于一体的综合性企业。覆盖十多个东南亚的国家和地区，经过公司全体同仁不懈的努力完善了广东和越南、缅甸境内各地的服务网点。服务网络覆盖越南境内的河内、海防、胡志明，缅甸境内的曼德勒、内比都、仰光等周边城市。为企业降低物流成本，减少中间转包环节，为客户更好地提供物流运输服务。
          </p>
        </li>
        <li>
          <p class="titles">服务范围</p>
          <p class="textsa">中国、香港、越南、缅甸、泰国、柬埔寨、老挝等。</p>
        </li>
        <li>
          <p class="titles">服务优势</p>
          <p class="textsa">
            依托凯洋国际物流在东南亚强大的仓储能力，提供东南亚全境派送+COD代收货款，全程轨迹跟踪。整合优势资源，为客户提供一站式全链条的物流解决方案，以最优的时效、最好的服务、极致的回款速度，为您的跨境业务提供强力保障。
          </p>
        </li>
        <li>
          <p class="titles">禁限寄物品</p>
          <p class="textsa" style="text-indent: 0px">
            1、危害宗族或当地秩序的印刷物； <br> 2、易燃易爆等危险品； <br>
            3、东南亚国家禁止进口的产品； <br> 4、我国禁止运输出口的产品。
          </p>
        </li>
        <li>
          <p class="titles">精品陆运-缅甸</p>
          <p class="textsa" style="text-indent: 0px">
            公司的陆运业务范围为：<br />
            1、零担运输（东莞—瑞丽—木姐—曼德勒—内比都—仰光）；<br />
            2、整车运输（全国—瑞丽—木姐—曼德勒—内比都—仰光—缅甸其他城市）；<br />
            3、缅甸快递（瑞丽—木姐—曼德勒—内比都—仰光）；<br />
            4、仰光、曼德勒仓储配送；<br /> 5、缅甸境内物流（仰光—缅甸其他城市）；<br />
            6、工程项目物流。
          </p>
        </li>
        <li>
          <p class="titles">中缅进出口清关</p>
          <p class="textsa" style="text-indent: 0px">
            瑞丽是陆运中国和缅甸的进出口清关公工作都在瑞丽和木姐进行。公司成立了专业的进出口清关团队，从而确保进出口货物快捷、高效通关。<br />
            瑞丽海关：代理报关、代理出口退税、边民报关；<br />
            缅甸海关：代理进出口报关、代缴税款、口岸企业备案。
          </p>
        </li>
        <li>
          <p class="titles">公司分部</p>
          <p class="textsa" style="text-indent: 0px">
            凯洋国际陆运部专注中缅物流专线多年，东莞、佛山、中山、昆明、瑞丽、姐告、曼德勒、仰光等地区均有分公司，陆运辐射长三角和珠三角。
          </p>
        </li>
        <li>
          <p class="titles">时效</p>
          <p class="textsa" style="text-indent: 0px">
            中缅快递：广东至仰光3-4天，4-6天，瑞丽姐告至仰光3天到货。 <br>
            中缅陆运：散货、整车服务、10-12天。
          </p>
        </li>
        <li>
          <p class="titles">缅甸双清物流专线运输物品</p>
          <p class="textsa" style="text-indent: 0px">
            交通工具类、机械设备类、电器类、建筑设备类、服装类、纺织品类、日用品类、玩具、信息电子、工矿机电、精密仪器、日用百货、医疗器械、医药、食品、农副产品、家用电器、电线电缆、建筑材料、纺织原料、服装、家具、橡塑产品、玻璃器皿、钢铁及原料、化工原料、化工产品、塑料原料及制品、照明材料以及各类民用物品、工业物品及大型矿山机械设备等。
          </p>
        </li>
      </ul>
    </div>
    <!-- 国际电商 -->
    <div class="details" v-show="checked == 3">
      <div class="jianjie-title">
        <div class="jianjie-title-e">THE INTERNATIONAL ELECTRICITY</div>
        <div class="jianjie-title-c">国际电商</div>
      </div>
      <ul>
        <li>
          <p class="textsa">
            广东凯洋国际物流供应链有限公司凭借双清门到门物流服务优势及市场信息优势，为国外客户提供一站式贸易采购服务，产品包括日用百货，汽车配件，建材家私等等，采购效率高，运送时速快，赢得国外客户大量好评。同时促进国内供应商在国际市场销售渠道上的开发，并提供国际贸易资金往来的保障。公司现面向全国各地产品供应商寻求合作。只要您和我们一样积极响应国家一带一路政策，具备产品及价格优势，您就是我们寻找的潜在贸易合作伙伴！
          </p>
        </li>
        <li>
          <p class="titles">优势物流运输服务</p>
          <p class="textsa" style="text-indent: 0px">
            <span>FBA 货运</span>跨境电商部门成立于2020<br /><span
              >货物中转</span
            >自营仓库分布于东莞、广州、深圳、越南、缅甸、马来西亚、美国、日本<br /><span
              >贴心服务</span
            >凯洋优秀操作团队，一对一专属客服指引，无缝对接；<br /><span
              >成本优势</span
            >一次性收费，无隐性费用，节省物流成本；<br /><span>系统优势</span
            >自发系统操作，全节点监控，多维度全程跟踪体系，实时掌控进程。<br />
          </p>
        </li>
      </ul>
    </div>
    <!-- 仓储服务 -->
    <div class="details" v-show="checked == 4">
      <div class="jianjie-title">
        <div class="jianjie-title-e">WAREHOUSE SERVICE</div>
        <div class="jianjie-title-c">仓储服务</div>
      </div>
      <ul>
        <li>
          <p class="textsa">
            广东凯洋国际物流供应链有限公司拥有数千平方米的仓库，可为国内外各大中型生产型企业提供进出口货物的装箱、仓储、货物分拣、重新包装、丈量尺码、称重、贴标签、打托、缠膜的装箱等综合物流操作服务。
          </p>
        </li>
        <li>
          <p class="titles">我们的优势有</p>
          <p class="textsa" style="text-indent: 0px">
            ◆仓库位于东莞市高埗镇百茂物流城，交通便利，是目前东莞最大的物流集散地。<br />
            ◆高素质的人员、稳定的团队、10年以上的处理能力以及新仓库的即将投入使用，可为客户提供进一步拓展业务的空间。<br />
            ◆先进的条形码管理系统，分拣、丈量尺码、扫描、贴标签、打托、缠膜、重新包装等精细化物流管理等操作，使客户节约了大量的精力和财力。<br />
            ◆仓库内可以提供验货场地。<br />
            ◆货物的在线查询，专业的仓储管理信息系统实现了于客户端的数据贡献，为客户时时提供在线查询和数据远程监控的服务。<br />
            ◆仓库设有监控，为客户提供装箱监控服务。<br />
            ◆完善的人员保护措施和设备，体现企业以人为本、长久发展的理念。<br />
          </p>
        </li>
      </ul>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Top from "../components/topnav.vue";
export default {
  data() {
    return {
      checked: 0,
    };
  },
  components: {
    Footer,
    Top,
  },
  methods: {
    tabClick(e) {
      this.checked = e;
    },
  },
  created() {
    this.checked = this.$route.query.cid;
  },
};
</script>

<style lang="scss" scoped>
#fdetail {
  width: 100%;
}
.fdetail {
  width: 100%;
  height: 100px;
  background-color: #00263d;
}
/* 服务切换 */
.fwtab {
  width: 840px;
  margin: 60px auto;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  > div {
    width: 140px;
    height: 60px;
    line-height: 60px;
    background-color: #cccccc;
    text-align: center;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
  }
  > .active {
    background-color: #f29709;
    color: white;
  }
}
// 内容
.details {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 152px;
  > ul {
    margin-top: 30px;
    > li {
      text-align: left;
      margin-top: 46px;
      .textsa {
        // display: inline-block;
        text-indent: 30px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        > span {
          font-weight: bold;
          width: 110px;
          display: inline-block;
        }
      }
      .titles {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #444444;
        margin-bottom: 30px;
      }
    }
    > li:first-child {
      margin-top: 0;
    }
  }
}
</style>